<template>
    <div
      class="card shadow-sm d-flex flex-column h-100 mx-auto position-relative card-main"
    
      :style="
        getCardBackground(pro.style) +
        getCardBorderCSS(pro.style) +
       getCardHeight(pro.flipCardsHeight)+
        getFontFamily(pro.style) +
        ListProductMaxWidth(
          isDesktopView ? pro.productsPerPage : pro.productsPerPageMobile
        )
      "
    >
  
    <div class="card__face card__face--front">
      <div class="d-flex flex-column h-100" >
          <div class=" " style="height:30%;" :style=" getPagePadding(pro.style) ">
            <p style=" -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;overflow-wrap: break-word; 
"  class="flipCardProductTitle"  :style="getTitleStyles(pro.style) + `-webkit-line-clamp: ${calculateTitleLineClamp};`">
              {{ getProductInfoDetail(pro.useProductVariant, product, "title") }}
            </p>
            <!-- Rating Div start -->
            <div
              v-if="product.rating && isJudgeMeInstalled"
              class="Review"
              v-html="product.rating"
              :style="getPriceColor(pro.style)"
            ></div>
            <!-- Rating Div end -->
          </div>
        <div
          v-if="
            showImage(
              getProductInfoDetail(pro.useProductVariant, product, 'image')
            )
          "
          style="height:70%;"
        >
          <div
            v-if="
              checkFileType(
                getProductInfoDetail(pro.useProductVariant, product, 'image')
              ) == 'video'
            "
            class="w-100 h-100"
           
          >
            <video
              ref="videoRef"
              :src="`${getProductInfoDetail(
                pro.useProductVariant,
                product,
                'image'
              )}`"
              :style="getImageFit(pro.style) + getImageTopBorderCSs(pro.style)"
              style="
               object-fit: cover;
                 object-position: center;
                height: 100%;
                width: 100%;
              "
              controls
            ></video>
          </div>
          <div v-else   class="w-100 h-100"  >
            <LazyImage
              v-if="
                getProductInfoDetail(
                  pro.useProductVariant,
                  product,
                  'image'
                ).startsWith('upload')
              "
              :src="
                '/' +
                getProductInfoDetail(pro.useProductVariant, product, 'image')
              "
              alt=""
              style="
               object-fit: cover;
                object-position: center;
                height: 100%;
                width: 100%;
              "
              :style="getImageFit(pro.style) + getImageTopBorderCSs(pro.style)"
            />
            <LazyImage
              v-else
              :src="getProductInfoDetail(pro.useProductVariant, product, 'image')"
              alt=""
              style="
              object-fit: cover;
                 object-position: center;
                height: 100%;
                width: 100%;
              "
              :style="getImageFit(pro.style) + getImageTopBorderCSs(pro.style)"
            />
          </div>
        </div>
      </div>
      </div>
      <div class="card__face card__face--back ">
          <div  :style="getPagePadding(pro.style)" class="h-100">
        <div class="d-flex flex-column  h-100 " style="padding-bottom: 10px;">
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="" style="flex:1;overflow: hidden auto;">
              <div v-if="!pro.hideDescription">
                <div
                  class="d-flex align-items-start mt-1"
                  :style="
                    limitProductDescription(
                      pro.limitDescription,
                      product.readMore
                    )
                  "
                >
                  <div
                    v-if="
                      pro.useBulletDescription &&
                      product.bullet_description &&
                      product.bullet_description.length
                    "
                  >
                    <div
                      v-for="(bulletPoint, index2) in product.bullet_description"
                      :key="index2"
                    >
                      <div
                        class="d-flex align-items-start mt-1"
                        :style="getBulletStyles(pro.style)"
                      >
                        <div class="mr-3">
                          <i class="fa" :class="pro.selectedBulletIcon"></i>
                        </div>
                        <span style="text-align: start">{{ bulletPoint }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="w-100" :style="`font-family:${pro.style.fontFamily};`" v-else v-html="product.description"></div>
                </div>
                <div
                  class=""
                  v-if="
                    pro.limitDescription &&
                    product.description &&
                    product.description.split(' ').join('').length > 150 &&
                    false
                  "
                >
                  <button
                    class="btn"
                    :class="product.readMore ? 'mt-2' : 'mt-2'"
                    :style="getPriceColor(pro.style)"
                    @click="product.readMore = !product.readMore"
                  >
                    <span v-if="product.readMore">{{
                      translateText("Less info")
                    }}</span>
                    <span v-else>{{ translateText("More info") }} </span>
                  </button>
                </div>
              </div>
            </div>
  
            <div class="" >
              <div class="d-flex flex-column h-100 justify-content-end ">

              
              <div
                class="d-flex align-items-center"
                :style="'justify-content:' + pro.style.textAlign + ';'"
              >
                <p
                  v-if="product.price && !isHidePrice"
                  :style="getPriceColor(pro.style)"
                  class="m-0 p-0 mr-2"
                >
                  <span
                    v-if="CurrencySign && currencyPos == 'left'"
                    :class="currencySpace ? 'mr-1' : null"
                    >{{ CurrencySign }}</span
                  >{{
                    getProductInfoDetail(pro.useProductVariant, product, "price")
                  }}<span
                    v-if="CurrencySign && currencyPos == 'right'"
                    :class="currencySpace ? 'ml-1' : null"
                    >{{ CurrencySign }}</span
                  >
                </p>
                <p
                  v-if="
                    product.compare_at_price &&
                    product.price !== product.compare_at_price &&
                    !isHidePrice
                  "
                  :style="getPriceColor(pro.style)"
                  class="m-0 p-0"
                >
                  <s>
                    <span
                      v-if="CurrencySign && currencyPos == 'left'"
                      :class="currencySpace ? 'mr-1' : null"
                      >{{ CurrencySign }}</span
                    >{{ product.compare_at_price
                    }}<span
                      v-if="CurrencySign && currencyPos == 'right'"
                      :class="currencySpace ? 'ml-1' : null"
                      >{{ CurrencySign }}</span
                    >
                  </s>
                </p>
              </div>
  
              <div class="mt-3" v-if="product.detail_link">
                <div
                  class="pb-2"
                  v-if="pro.useProductVariant && product && product.variants.length > 0"
                >
                  <VaraintSelectComp :pro="pro" :product="product" />
                </div>
  
                <button
                  class="ButtonHover"
                  v-if="pro.useAddToCart"
                  :style="getProductButtonCss(pro.style)"
                  :disabled="disableAddtoCartBtn"
                  @click="AddToCart(product, index)"
                >
                  <span v-if="!product.loading">{{
                    pro.useAddToCartText || "Add to Cart"
                  }}</span>
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
                <button
                  class="ButtonHover"
                  v-else
                  :style="getProductButtonCss(pro.style)"
                  @click="goToURLClicked(product.detail_link, product.id)"
                >
                  <span v-if="!product.loading"> {{ pro.buttonText }}</span>
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
            </div>
           
          </div>
        </div>
      </div>
      </div>
  
    </div>
   
  </template>
  
  <script>
  import cssFunctionMixin from "../../../customize/mixin/cssFunction";
  export default {
    mixins: [cssFunctionMixin],
    components:{
  VaraintSelectComp: () =>
      import(
        /* webpackChunkName: "VaraintSelectComp" */ "../../VaraintSelectComp.vue"
      )
},
    props: [
      "pro",
      "index",
      "isTest",
      "isDesktopView",
      "productsLength",
      "product",
      "displayProductsLength",
      "isJudgeMeInstalled",
      "isHidePrice",
      "CurrencySign",
      "currencyPos",
      "currencySpace",
      "disableAddtoCartBtn",
      "AddToCart",
      "goToURLClicked",
      "getProductInfoDetail",
      "checkFileType",
      "getImageFit",
      "getImageTopBorderCSs",
      "getTitleStyles",
      "getPriceColor",
    ],
    data(){
      return {
        isTruncated: false,
      }
    },
    methods: {
      getCardHeight(height){
        if(!height) return `height:400px !important;`
        return `height:${height}px !important;`
      },
      showImage(image) {
        return !(
          image.includes("default_start_page.png") ||
          image.includes("/images/image_placeholder.png")
        );
      },
      getCardBackground(property) {
        return "background-color:" + property.backgroundColor + ";";
      },
      getCardBorderCSS(property) {
        return (
          " border-radius:" +
          property.borderRadius +
          "px; border:" +
          property.borderSize +
          "px solid " +
          property.borderColor +
          ";"
        );
      },
      getPagePadding(property, isDesktopView) {
  const getPaddingValue = (desktopValue, mobileValue) =>
    isDesktopView || mobileValue === undefined ? desktopValue : mobileValue;

  const paddingTop = getPaddingValue(property.pagePaddingTop, property.pageMobilePaddingTop);
  const paddingRight = getPaddingValue(property.pagePaddingRight, property.pageMobilePaddingRight);
  const paddingBottom = getPaddingValue(property.pagePaddingBottom, property.pageMobilePaddingBottom);
  const paddingLeft = getPaddingValue(property.pagePaddingLeft, property.pageMobilePaddingLeft);

  return `; text-align: ${property.textAlign}; padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px; `;
},
      getFontFamily(property) {
        return "font-family:" + property.fontFamily + ";";
      },
    },
    computed:{
      calculateTitleLineClamp(){
  let value = 4;

if (this.pro.style && this.pro.style.fontSize) {
  const fontSize = parseInt(this.pro.style.fontSize, 10);
  if (fontSize <= 15) {
    value = 5;
  } else if (fontSize > 19) {
    value = 3;
  }
}

return value;
      }
    }
  };
  </script>
  
  <style scoped>
  
   .card-main {
      /* height: 400px !important; */
      /* margin-bottom:30px; */
    position: relative;
    cursor: pointer;
    transition: 1s ease-in-out;
    transform-style: preserve-3d;
    /* overflow: hidden; */
  }
  .card-main:hover {
    transform: rotateY(0.5turn);
  }
  .card-main .card__face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transform: rotateX(0deg);
    transition: 0.6s ease-in-out;
  }
  
  .card-main .card__face--back {
    transform: rotateY(0.5turn);
  }

  .truncate {
  white-space: nowrap;
}
  </style>
  